const settings = {
  env: process.env.NODE_ENV,
  backendUrl: process.env.REACT_APP_BACKEND_URL || 'https://42z8rspuui.execute-api.eu-west-1.amazonaws.com/prod/',
  contractNetwork: process.env.REACT_APP_CONTRACT_NETWORK || 'https://eth-rinkeby.alchemyapi.io/v2/Qlnkje6Z022PVY1M-XAmnk0o8qEUn2x2', // 'https://eth-rinkeby.alchemyapi.io/v2/i7AgvK6Qm4PatEPhavZ2FoCiOfIVb3gs',
  contractEtherscanUrl: process.env.REACT_APP_CONTRACT_ETHERSCAN_URL || 'https://rinkeby.etherscan.io/tx/',
  contractOpenseaUrl: process.env.REACT_APP_CONTRACT_OPENSEA_URL || 'https://testnets.opensea.io',
  contractOpenseaApi: process.env.REACT_APP_CONTRACT_OPENSEA_API,
  contractRaribleUrl: process.env.REACT_APP_CONTRACT_RARIBLE_URL,
  contractGateway: process.env.REACT_APP_CONTRACT_GATEWAY || 'https://eth-rinkeby.alchemyapi.io/v2/Qlnkje6Z022PVY1M-XAmnk0o8qEUn2x2', // 'https://eth-rinkeby.alchemyapi.io/v2/i7AgvK6Qm4PatEPhavZ2FoCiOfIVb3gs',
  contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS || '0x468CD45eb032Fa3571FB380fC1267F00d20C983C',

  onRaffle: Boolean(Number(process.env.REACT_APP_ON_RAFFLE)),
  onPresale: Boolean(Number(process.env.REACT_APP_ON_PRESALE)),
  onSale: Boolean(Number(process.env.REACT_APP_ON_SALE)),

  enableSwitchingNetwork: Boolean(Number(process.env.REACT_APP_ENABLE_SWITCHING_NETWORK)),
}

if (settings.env === 'development') {
  window.settings = settings
}

export {
  settings as default,
}