import React, { useEffect, useRef, useState } from 'react'
// Import Swiper React components

// swiper bundle styles
import 'swiper/swiper-bundle.min.css'

// swiper core styles
import 'swiper/swiper.min.css'

import { Swiper, SwiperSlide } from 'swiper/react';

import { CSSTransition } from 'react-transition-group'
import { AccountName, ConnectWalletInternal } from './connect/connect-wallet'
import { useWeb3React } from '@web3-react/core'
import { fetchFromBackend } from './functions'
import { getBalance, getSignMessage, mint, parseLogs, verifySignature } from './connect/functions'

import settings from './settings'

const Hello = ({ step, setStep }) => {
  return (
    <div className="text-primary">
      i'm non.<br/>
      who are you? <br/>

      <div className={'connect-wallet'}>
        <ConnectWallet setStep={setStep}/>
      </div>
    </div>

  )
}

const ConnectWallet = ({ setStep, ...props }) => {
  const [connect, setConnect] = useState(false)
  const { account } = useWeb3React()

  useEffect(() => {
    console.log(account)

    if (account) {
      setStep(2)
    }

  }, [account])

  return <>
    <div className="btn-primary" {...props} onClick={() => {
      setConnect(true)
    }}>
      {connect && !account ? <div style={{ minWidth: '80px' }}>...</div> : 'connect wallet'}
    </div>
    <br/>
    <div className="text-secondary">
      {connect && <ConnectWalletInternal/>}
    </div>
  </>
}

const Balances = ({ balance, setBalance, setStep }) => {
  const { account } = useWeb3React()

  useEffect(() => {
    (async () => {
      const response = await getBalance(account)

      console.log(response)

      setTimeout(() => {
        setBalance(response)

        if (!response.error) {
          setTimeout(() => {
            setStep(3)
          }, 3000)
        }
      }, 300)
    })()
  }, [account])

  return <>
    <CSSTransition
      in={!!balance}
      timeout={1000}
      classNames="connect-wallet-transition"
      unmountOnExit
      appear
    >
      <div>
        {balance && (balance.error ? <>
          sorry, fixed just for $ASH and $ENS holders
        </> : <>I see you have {balance.ash} $ASH, {balance.ens} $ENS</>)}
      </div>
    </CSSTransition>
  </>
}

const Welcome = ({ ...props }) => {
  return <>
    <div className="text-primary">
      welcome <AccountName/> <br/>
      <Balances {...props}/>
    </div>
  </>
}

const MintButton = ({ mintedTokens, setMintedTokens, setStep, ...props }) => {
  const { account, library } = useWeb3React()
  const [step_, setStep_] = useState('sign')
  const [signState, setSignState] = useState({})
  const [loading, setLoading] = useState(false)
  const [mintState, setMintState] = useState(undefined)

  useEffect(() => {

  }, [account, library, step_])

  async function handleSignClick () {
    setLoading(true)

    const acc = String(account)
    const { message } = await getSignMessage(acc)
    const signature = await library.getSigner(acc).signMessage(message)

    try {
      const { status, nonce, proof, mass } = await verifySignature(acc, signature)

      if (status !== 'ok') {
        throw new Error(`Invalid signature - ${status}`)
      }

      setSignState({
        signature,
        nonce,
        proof,
        mass,
      })

      setLoading(false)
      setStep_('mint')
    } catch (e) {
      console.error(e)
    }
  }

  const [message, setMessage] = useState()

  async function handleMint() {
    const { nonce, proof, mass } = signState

    setMessage(undefined)

    try {
      const tx = await mint(library, account, mass, nonce, proof)

      setMintState({ tx })
      setMessage(['', <>
          <a href={`https://etherscan.io/tx/${tx.hash}`} target={'_blank'}>wait.</a> transaction has been sent.
        </>])

      const receipt = await tx.wait()

      const receiptLogs = parseLogs(library, receipt)

      console.log(receiptLogs)

      setTimeout(() => {
        const tokenIds = receiptLogs
          .filter(log => log.name === 'Transfer')
          .map(receiptLog => receiptLog.args[2].toString())

        setMintedTokens(tokenIds)
        setStep(4)
        setMessage(undefined)
      }, 500)
    }
    catch (e) {
      // e.code === 4001 - User denied transaction signature.
      console.error(e)

      console.log(e.code)

      if (e.code === 4001) {
        setMessage(['', 'you are not ready for fixed.']) //  (gas * price + value)
      } else if (e.code === 'INSUFFICIENT_FUNDS') {
        setMessage(['', 'insufficient funds for transaction cost']) //  (gas * price + value)
      } else {
        const errorDetailIndex = e.message.indexOf(' (error=')
        if (errorDetailIndex !== -1) {
          setMessage(['', e.message.substring(0, errorDetailIndex)])
        } else {
          setMessage(['', e.message])
        }
      }
    }
  }

  useEffect(() => {
    if (signState.proof) {
      handleMint()
    }
  }, [signState])

  return <>
    <CSSTransition
      in={true}
      timeout={1000}
      classNames="connect-wallet-transition"
      unmountOnExit
      appear
    >
      <div className={'mt-4'}>
        <div className="btn-primary" onClick={() => {
          if (step_ === 'sign') {
            handleSignClick()
          }
        }}>
          {loading ? '...' : step_ === 'sign' ? 'MINT' : step_ === 'mint' && 'MINT'}
        </div> <br/>
        {message && (
          <>
            <div className="text-secondary mt-4">
              {message[1]}
            </div>
          </>
        )}
      </div>
    </CSSTransition>
  </>
}

const Mint = ({ balance, ...props }) => {
  return <>
    <div className="text-primary">
      you can mint "{balance.mass} fixed." for free <br/>
      <MintButton {...props}/>
    </div>
  </>
}

const Success = ({ mintedTokens }) => {
  const tokenUrls = mintedTokens && mintedTokens.map(
    tokenId => [
      tokenId,
      `${settings.contractOpenseaUrl}/assets/${settings.contractAddress}/${tokenId}`,
    ]
  )

  return (
    <div className="text-primary">
      {(tokenUrls ?? []).map(([tokenId, openseaUrl, raribleUrl]) => (
        <div key={tokenId}>
          <a href={openseaUrl} target={'_blank'} rel="noreferrer">fixed.</a>
        </div>
      ))}
    </div>

  )
}

export default () => {
  const [step, setStep] = useState(0)
  const [balance, setBalance] = useState()
  const [swiper, setSwiper] = useState()
  const [ mintedTokens, setMintedTokens ] = useState(undefined)


  const steps = [
    [0, Hello],
    [2, Welcome, true],
    [3, Mint, true],
    [4, Success, true],
  ]

  useEffect(() => {
    const slide = steps.find(s => s[0] === step)

    console.log(slide)

    if (slide && slide[2]) {
      setTimeout(() => {
        swiper.slideNext(3000)
      }, 100)
    }
  }, [step])

  useEffect(() => {
    if (step === 0) {
      setTimeout(() => {
        setStep(1)
      }, 1000)
    }
  })

  return (
    <>
      <Swiper
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => {
          console.log(swiper)
          setSwiper(swiper)
        }}
        speed={3000}
        slidesPerView={'auto'}
        centerInsufficientSlides={true}
        centeredSlides={true}
        spaceBetween={40}
        direction={'vertical'}
        pagination={false}
        allowSlidePrev={false}
        autoplay={false}
        observer={true}
        observeParents={true}
        freeMode={true}
        watchSlidesVisibility={true}
        watchSlidesProgress={true}
        className="mySwiper">
        {
          steps.map(([stepNumber, Component]) => {
            if (step >= stepNumber) {
              return <SwiperSlide>
                <Component step={step} setStep={setStep} balance={balance} setBalance={setBalance} mintedTokens={mintedTokens} setMintedTokens={setMintedTokens}/>
              </SwiperSlide>
            }
          })
        }
      </Swiper>
    </>
  )
};