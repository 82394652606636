import React, { Fragment, useEffect, useState } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from '@web3-react/injected-connector'
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from '@web3-react/walletconnect-connector'
import { formatEther } from '@ethersproject/units'
import { handleChainChange } from '../functions'
import settings from '../settings'
import { useInactiveListener } from './hooks'

import {
  injected,
  walletconnect
} from './connectors'

import detectEthereumProvider from '@metamask/detect-provider'
// import { Spinner } from '../components/Spinner'

const ConnectorNames = {
  Injected: 'MetaMask',
  WalletConnect: 'WalletConnect'
}

const connectorsByName = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect
}

export const AccountName = ({ handleMouseEnter, handleMouseLeave, mobile }) => {
  const { account, library, deactivate, connector } = useWeb3React()
  const [ens, setENS] = useState()

  useEffect(() => {
    (async () => {
      if (account) {
        const name = await library.lookupAddress(account)
        setENS(name)
      }
    })()
  }, [account, library])

  const accountString = ens ? ens : (account ? account.substring(0, 6) + '...' + account.substring(38) : '')
  const balance = Balance()

  return accountString
}

function getErrorMessage (error) {
  if (error instanceof NoEthereumProviderError) {
    return <>No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on
      mobile. <br/> Or use other options.</>
  } else if (error instanceof UnsupportedChainIdError) {
    return 'You\'re connected to an unsupported network.'
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorWalletConnect
  ) {
    return 'Please authorize this website to access your Ethereum account.'
  } else {
    console.error(error)
    return 'An unknown error occurred. Please try again after a while or contact with us.'
  }
}

export function Balance () {
  const { account, library, chainId } = useWeb3React()

  const [balance, setBalance] = React.useState()

  React.useEffect(() => {
    if (!!account && !!library) {
      let stale = false

      library
        .getBalance(account)
        .then((balance) => {
          if (!stale) {
            setBalance(balance)
          }
        })
        .catch(() => {
          if (!stale) {
            setBalance(null)
          }
        })

      return () => {
        stale = true
        setBalance(undefined)
      }
    }
  }, [account, library, chainId]) // ensures refresh if referential identity of library doesn't change across chainIds

  const balanceArray = balance === null ? '' : balance ? `${formatEther(balance)}`.split('.') : ''

  return balanceArray.length > 0 ? `${balanceArray[0]}.${balanceArray[1].substring(0, 3)} ETH` : ''
}

export function ConnectWalletInternal (props) {
  const context = useWeb3React()
  const { connector, chainId, activate, error } = context

  const [isMetamaskAvailable, setIsMetamaskAvailable] = useState(false)

  const [, setMetamaskMessage] = useState('')

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState()

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  // const triedEager = useEagerConnect()

  useEffect(() => {
    // if (activatingConnector && activatingConnector === connector) {
    //   setActivatingConnector(undefined)
    //
    //   if (props.openModal) {
    //     showModal(props.openModal)
    //   } else {
    //     hideModal(ConnectWalletModal)
    //   }
    // }
  }, [activatingConnector, connector, props.openModal])

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  // useInactiveListener(!triedEager || !!activatingConnector)
  useInactiveListener(!!activatingConnector)

  useEffect(() => {
    if (settings.enableSwitchingNetwork && chainId && chainId !== 1) {
      handleChainChange()
    }
  }, [chainId])

  useEffect(() => {
    (async () => {
      const provider = await detectEthereumProvider()

      let connector
      if (provider) {
        connector = connectorsByName['MetaMask']

        setIsMetamaskAvailable(true)
      } else {
        connector = connectorsByName['WalletConnect']

        setIsMetamaskAvailable(false)
      }

      setActivatingConnector(connector)
      activate(connector)
    })()

  }, [])

  return (
    <>
      {error && getErrorMessage(error)}
    </>
  )
}