import { ethers } from 'ethers'
import { fetchFromBackend } from '../functions'
import contractAbi from '../connect/contract.abi.json'
import settings from '../settings'

export function getContract(library) {
  const contract = new ethers.Contract(settings.contractAddress, contractAbi, library)

  if (settings.env === 'development') {
    window.contract = contract
  }

  return contract
}

export function isPresaleActive(library) {
  const contract = getContract(library)

  return contract.IS_PRESALE_ACTIVE()
}

export function getCurrentMintCount(library, address) {
  const contract = getContract(library)

  return contract.getCurrentMintCount(address)
}

export async function mint(library, address, mass, nonce, proof) {
  const contract = getContract(library)
  const signer = library.getSigner(address)

  try {
    const tx = await contract.connect(signer).mint(
      mass,
      nonce,
      proof
    )

    if (settings.env === 'development') {
      window.lastTx = tx
    }

    return tx
  } catch (err) {
    throw err
  }
}

export function getBalance(account) {
  return fetchFromBackend(
    '/non/balance',
    { address: ethers.utils.getAddress(account) }
  )
}

export function getSignMessage(account) {
  return fetchFromBackend(
    '/non/message',
    { address: ethers.utils.getAddress(account) }
  )
}

export function verifySignature(account, signature) {
  const url = new URL(window.location);
  const token = url.searchParams.get("token");

  const parameters = { address: ethers.utils.getAddress(account), signature }

  if (token) {
    parameters.token = token;
  }

  return fetchFromBackend(
    '/non/verify',
    parameters
  )
}

export function parseLogs(library, receipt) {
  const contract = getContract(library)

  const logs = receipt.logs.map(x => contract.interface.parseLog(x))

  return logs
}