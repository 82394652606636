import logo from './logo.svg'
import './App.css'

import { useState } from 'react'
import MainSlider from './MainSlider'
import { Web3ReactProvider } from '@web3-react/core'

import { getLibrary } from './functions'

function App () {
  return <>
    <Web3ReactProvider getLibrary={getLibrary}>
    <div className="app">
      <MainSlider />
    </div>
    </Web3ReactProvider>
  </>
}

export default App
